<template>
  <div>
    <el-tabs
      type="card"
      lazy="true"
      :value="tabValue"
      @tab-click="tabsRoutingHandler">
      <el-tab-pane name="detail-info">
        <span slot="label"><i class="el-icon-info" /> Детальная информация</span>
      </el-tab-pane>
      <el-tab-pane name="reviews">
        <span slot="label"><i class="el-icon-chat-line-square" /> Отзывы</span>
      </el-tab-pane>
      <!-- <el-tab-pane name="tires">
        <span slot="label"><i class="el-icon-help" /> Шины</span>
      </el-tab-pane> -->
      <el-tab-pane name="structure">
        <span slot="label"><i class="el-icon-data-analysis" /> Структура</span>
      </el-tab-pane>
      <el-tab-pane name="edit">
        <span slot="label"><i class="el-icon-edit" /> Редактор</span>
      </el-tab-pane>
    </el-tabs>
    <router-view />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'TireModel',
  computed: {
    ...mapGetters(['TIRE_MODEL_LIST', 'TIRE_MODEL']),
    tabValue() {
      return this.$route.path.split('/')[3] || 'detail-info';
    },
  },
  watch: {
    TIRE_MODEL({ name }) {
      this.setPageTitle(name);
    },
  },
  mounted() {
    const tireModelId = this.$route.params.tire_model_id;
    if (tireModelId !== this.TIRE_MODEL.id) {
      this.getTireModel(tireModelId);
    }
  },
  destroyed() {
    this.setTireReviews([]);
  },
  methods: {
    ...mapMutations(['setPageTitle', 'setTireReviews']),
    ...mapActions(['getTireModel']),
    tabsRoutingHandler({ name }) {
      if (name !== this.tabValue) {
        const tireModelId = this.$route.params.tire_model_id;
        if (name === 'detail-info') {
          this.$router.push(`/tire-models/${tireModelId}/`);
        } else if (name === 'reviews') {
          this.$router.push(`/tire-models/${tireModelId}/${name}?from=site`);
        } else {
          this.$router.push(`/tire-models/${tireModelId}/${name}`);
        }
      }
    },
  },
};
</script>
<style lang="scss">
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>
